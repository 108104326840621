.footer {
    width: 100%;
    background-image: url('../../img/footer_bg.jpg');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    box-shadow: 0 -10 / $setFs + rem 10 / $setFs + rem rgba(#000, .2);
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;

    .container {
        max-width: 1600 / $setFs + rem;
        padding-top: 150 / $setFs + rem;
        padding-bottom: 145 / $setFs + rem;
    }
}

.footer_handler {
    position: absolute;
    bottom: 100%;
    right: 0;
    margin-bottom: -7 / $setFs + rem;
    margin-right: -10 / $setFs + rem;
}

.footer_handler_link {
    @include acc;

    &:hover {
        .footer_handler_btn {
            &::after {
                opacity: 1;
            }
        }
    }
}

.footer_handler_btn {
    width: 197 / $setFs + rem;
    height: 79 / $setFs + rem;

    &::before,
    &::after {
        content: '';
        width: 100%;
        height: 100%;
        background-image: url('../../img/footer_btn.png');
        background-size: 200% auto;
        position: absolute;
        top: 0;
        left: 0;
    }

    &::before {
        background-position: 0 0;
    }

    &::after {
        background-position: 100% 0;
        opacity: 0;
        transition: opacity .3s ease-out;
    }
}

.footer_row {
    display: flex;
    justify-content: space-between;
}

.footer_col {

    &.col_1 {
        max-width: 550 / $setFs + rem;
        flex: 550 / $setFs + rem 1 auto;
        border-right: 1 / $setFs + rem solid #000;
        padding-right: 53 / $setFs + rem;
    }

    &.col_2 {
        flex: 1;
        padding-left: 53 / $setFs + rem;
    }

    .footer_col {
        max-width: 380 / $setFs + rem;
    }

    .small {
        font-size: 12 / $setFs + rem;
    }
}

.footer_logo {
    width: 170 / $setFs + rem;
    margin-bottom: 30 / $setFs + rem;
}

.footer_maintitle {
    font-size: 29 / $setFs + rem;
    font-family: $font1;
    margin-bottom: 30 / $setFs + rem;
}

.footer_title,
.footer_txt {
    font-size: 15 / $setFs + rem;
    line-height: 2;

    a {
        display: inline;

        &:hover {
            color: $color2;
        }
    }
}