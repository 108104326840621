.lightbox {
    width: 100%;
    height: 100%;
    padding-top: 40 / $setFs + rem;
    padding-bottom: 40 / $setFs + rem;
    box-sizing: border-box;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    left: 0;
    background-image: url('../../img/bg_2.jpg');
    background-size: cover;
    background-position: center top;
    align-items: center;
    justify-content: center;
    display: none;
    opacity: 0;
    z-index: 999;

    @media screen and (min-width: 951px) {
        &::-webkit-scrollbar {
            width: 4 / $setFs + rem;
        }

        &::-webkit-scrollbar-track {
            border-radius: 4 / $setFs + rem;
            background: rgba(255, 255, 255, 0.1);
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, 0.3);
        }
    }

    @media screen and (max-width: 950px) {
        padding-top: 65 / $setFs + rem;
        padding-bottom: 65 / $setFs + rem;
    }
}


.lb_board {
    width: 100%;
    max-width: 1730 / $setFs + rem;
    margin: auto;
    position: relative;
}

.btnClose {
    width: 30 / $setFs + rem;
    height: 30 / $setFs + rem;
    position: absolute;
    top: -7 / $setFs + rem;
    right: 0;

    @media screen and (max-width: 480px) {
        right: 10 / $setFs + rem;
    }

    &:before,
    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: 21px auto;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 0;
        transition: opacity 0.2s ease-out;
    }

    &:before {
        background-image: url('../../img/btnClose.png');
    }

    &:after {
        background-image: url('../../img/btnClose_hvr.png');
        opacity: 0;
    }

    &:hover {
        &:before {
            opacity: 0;
        }

        &:after {
            opacity: 1;
        }
    }
}

.btnClose_link {
    height: 100%;
    @include acc;
}

.lb_btn {
    cursor: pointer;
}