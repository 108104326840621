.loading {
    width: 100%;
    height: 100%;
    background: #ECD9BE;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    opacity: .99;
    transition: transform .6s cubic-bezier(0.33, 1, 0.68, 1);

    &.loading_hide {
        transform: translateY(-100%);
    }
}

.loader_container {
    position: relative;
}

.loading_txt {
    text-align: center;
    font-size: 17 / $setFs + rem;
    font-family: $font1;
    letter-spacing: 2px;
    line-height: 2;
}