.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 70;

    .container {
        max-width: none;
    }
}

.menu {
    width: 100%;
    height: 100vh;
    background-image: url('../../img/bg.jpg');
    background-size: 100% auto;
    background-repeat: repeat-y;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
}

.nav {
    width: 100%;

    .container {
        max-width: 1394 / $setFs + rem;
        padding-top: 90 / $setFs + rem;
    }
}

.nav_li {
    padding: 18 / $setFs + rem 0;
}

.nav_link {
    font-size: 60 / $setFs + rem;
    font-family: $font1;
    letter-spacing: 5 / $setFs + rem;

    &:hover,
    &.active {
        color: #558611;
    }
}

.hamburger {
    width: 83 / $setFs + rem;
    position: absolute;
    top: 21 / $setFs + rem;
    left: 0;
}

.hamburger_link {

    &.active,
    &:hover,
    &:focus {
        .hamburger_icon {
            &::before {
                opacity: 0;
            }

            &::after {
                opacity: 1;
            }
        }
    }
}

.hamburger_icon {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    &::before,
    &::after {
        content: '';
        width: 100%;
        height: 100%;
        background-image: url('../../img/icon_sprite_2.png');
        background-size: 200%;
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity .1s ease-out;
    }

    &::before {
        background-position: 0 0%;
    }

    &::after {
        background-position: 100% 0%;
        opacity: 0;
    }
}

// .hamburger_img {
//     transition: opacity .1s ease-out;

//     &.hvr {
//         position: absolute;
//         top: 0;
//         left: 0;
//         opacity: 0;
//     }
// }

.hamburger_txt {
    font-size: 19 / $setFs + rem;
    font-family: $font1;
    white-space: nowrap;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
}