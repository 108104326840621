@import "./reset";
@import "./variable";
@import "./base";
@import "./layout/loading";
@import "./layout/header";
@import "./layout/footer";
@import "./components/lightbox";
@import "./components/maintitle";
@import "./components/funcbtn";


.bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: .3;
    z-index: 90;
}

.instruction_block {
    width: 100%;
    height: 100%;
    background: rgba($color, .85);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 40;
}

.instruction {
    position: absolute;

    &.ins_scrolldown {
        bottom: 15 / $setFs + rem;
        left: 50%;
        transform: translateX(-50%);

        .instruction_arrow {
            margin-top: 15 / $setFs + rem;
        }

        .instruction_txt {
            text-align: center;
        }
    }

    &.ins_search {
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        margin-right: 30 / $setFs + rem;
    }

    &.ins_hamburger {
        left: 100%;
        top: 50%;
        transform: translateY(-22%);
        display: flex;
        align-items: center;
        margin-left: 20 / $setFs + rem;
    }

    &.ins_selected_thumbs {
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
    }

    &.ins_backtotop {
        left: 100%;
        top: 50%;
        transform: translateY(-22%);
        display: flex;
        align-items: center;
        margin-left: 20 / $setFs + rem;
    }

    &.ins_funcbtn {
        right: 100%;
        top: 50%;
        transform: translateY(-65%);
        display: flex;
        align-items: center;
        margin-right: -5 / $setFs + rem;

        .instruction_arrow {
            transform: rotate(180deg);
            margin-left: 5 / $setFs + rem;
        }
    }

    &.ins_back {
        text-align: right;
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        margin-right: -5 / $setFs + rem;

        .instruction_arrow {
            transform: rotate(180deg);
            margin-left: 5 / $setFs + rem;
        }
    }
}

.instruction_arrow {
    &.down {
        width: 14 / $setFs + rem;
        margin: 0 auto 16 / $setFs + rem;
    }

    &.right {
        width: 30 / $setFs + rem;
    }

    &.left {
        width: 37 / $setFs + rem;
        margin-right: 15 / $setFs + rem;
    }
}

.instruction_txt {
    font-size: 22 / $setFs + rem;
    color: #154e72;
    font-family: $font1;
    letter-spacing: 2 / $setFs + rem;
    white-space: nowrap;
    line-height: (32/23);
}

.instruction_close {
    padding-top: 90 / $setFs + rem;

    .instruction_txt {
        font-size: 26 / $setFs + rem;
        color: $txtColor;
        font-family: $font1;
        letter-spacing: 3 / $setFs + rem;
        white-space: nowrap;
        margin-top: 10 / $setFs + rem;
    }
}

.instruction_close_link {

    .instruction_txt {
        transition: color .2s ease-out;
    }

    // &:focus,
    // &:hover {
    //     .instruction_txt {
    //         color: #154e72;
    //     }
    // }
}

.instruction_close_img {
    width: 30 / $setFs + rem;
    margin: 0 auto;
}