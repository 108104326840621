@font-face {
    // src: url("../../fonts/GenWanMin-SB.ttf") format("truetype");
    src: url("../../fonts/GenWanMin-SB.woff2") format('woff2');
    font-family: 'GenWanMin-SB';
    font-display: swap;
}

@font-face {
    src: url("../../fonts/Oradano.otf") format("opentype");
    font-family: 'Oradano';
    font-display: swap;
}

$color: #ECD9BE;
$color2: #69853a;
$setFs: 16;
$txtColor: #323232;
$font: 'GenWanMin-SB',
"微軟正黑體",
"蘋果儷黑體",
Arial,
sans-serif,
Verdana;
$font1: 'Oradano',
$font;
// $font1: 'EBGaramond-Regular',
// 'NotoSerifTC-Medium',
// $font;
$size_XL: 113 / $setFs+rem;
$size_L: 55 / $setFs+rem;
$size_M: 18 / $setFs+rem;


@mixin word_limit($line: 2) {
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin _w {
    @media screen and (min-width: 951px) {
        &:after {
            border-color: #fff;
        }
    }
}

@mixin acc {
    @media screen and (min-width: 951px) {
        &:after {
            content: "";
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            border: 2px dotted $color2;
            border-radius: 3 / $setFs + rem;
            box-sizing: border-box;
            position: absolute;
            top: -2px;
            left: -2px;
            z-index: 5;
            opacity: 0;
            transition: opacity 0.1s ease-out;
        }

        &:focus {
            &:after {
                opacity: 1;
            }
        }
    }
}

@mixin acc_before {
    @media screen and (min-width: 951px) {
        &:before {
            content: "";
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            border: 2px dotted $color;
            border-radius: 3 / $setFs + rem;
            box-sizing: border-box;
            position: absolute;
            top: -2px;
            left: -2px;
            z-index: 5;
            opacity: 0;
            transition: opacity 0.1s ease-out;
        }

        &:focus {
            &:before {
                opacity: 1;
            }
        }
    }
}

@mixin acc_w {
    @include acc;
    @include _w;
}

@mixin acc_before_w {
    @include acc_before;
    @include _w;
}

@mixin clearfix {
    &:before {
        content: "";
        display: table;
    }

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin buttonApp($mainColor) {
    color: #fff;
    text-shadow: -1px -1px 0 darken($mainColor, 10%);
    box-sizing: border-box;
    background: $mainColor;
    background: lighten($mainColor, 3%);
    border: 1px solid darken($mainColor, 5%);
    border-right: 1px solid darken($mainColor, 10%);
    border-bottom: 3px solid darken($mainColor, 15%);
    box-shadow: 1px 1px 7px rgba(darken($mainColor, 15%), .8);
    border-radius: 1px;
    transition: all 0.1s ease-out;
}

@mixin buttonApp_hvr($mainColor) {
    color: lighten($mainColor, 40%);
    text-shadow: -1px -1px 0 darken($mainColor, 22%);
    background: $mainColor;
    border: 1px solid darken($mainColor, 5%);
    border-bottom: 1px solid darken($mainColor, 5%);
    box-shadow: 1px 1px 3px rgba(darken($mainColor, 15%), .8);
    transition: all 0.1s linear;
}