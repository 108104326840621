$winSize: 1920;



html {
    width: 100%;
    font-family: $font;
    color: $txtColor;
    font-size: $setFs/$winSize * 100vw;
    background-image: url('../../img/bg.jpg');
    background-size: 100% auto;
    background-repeat: repeat-y;
    position: relative;

    @media screen and (max-width: 950px) {
        $winSize: 950;
        font-size: $setFs/$winSize * 100vw;
    }

    @media screen and (max-width: 480px) {
        $winSize: 480;
        font-size: $setFs/$winSize * 100vw;
    }
}

body {
    @extend html;
    //禁止選取文字
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.wrapper {
    width: 100%;
    min-height: 100vh;
    line-height: 1.4;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.container {
    width: calc(100% - #{80 / $setFs + rem});
    max-width: 1440 / $setFs + rem;
    position: relative;
    margin: 0 auto;
    z-index: 1;

    @media screen and (max-width: 950px) {
        width: calc(100% - #{40 / $setFs + rem});
    }

    @media screen and (max-width: 480px) {
        width: calc(100% - #{20 / $setFs + rem});
    }
}

.container_inner {
    @extend .container;
    width: calc(100% - #{20 / $setFs + rem});
    max-width: 1000 / $setFs + rem;
}

section {
    position: relative;
    z-index: 1;
}