.funcbtn_box {
    padding-right: 29px;
    padding-bottom: 40px;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 60;
}

.funcbtn_li {
    position: relative;
    padding: 2px;
}

.funcbtn {
    width: 50px;
    border: 0;
    background-color: transparent;
    padding: 0;
    display: block;
    appearance: none;
    margin: 0 auto;
    position: relative;
    cursor: pointer;

    &:focus,
    &:hover {
        &::before {
            opacity: 0;
        }

        &::after {
            opacity: 1;
        }
    }

    &::before,
    &::after {
        content: '';
        width: 100%;
        height: 100%;
        background-image: url('../../img/icon_sprite_1.png');
        background-size: 200%;
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity .1s ease-out;
    }

    &::after {
        opacity: 0;
    }

    a {
        z-index: 1;
    }
}

.func_back,
.func_backtotop {
    width: 76px;
    position: fixed;
    z-index: 10;

    &::before,
    &::after {
        background-image: url('../../img/icon_sprite_5.png');
        background-size: 200%;
    }
}

.func_back {
    top: 24px;
    right: 35px;
    z-index: 50;

    &::after {
        background-position: 100% 0;
    }
}

.func_backtotop {
    bottom: 48px;
    left: 40px;
    z-index: 50;
    transition: opacity .3s ease-out;

    &::before {
        background-position: 0% 100 / 2 * 1 * 1%;
    }

    &::after {
        background-position: 100% 100 / 2 * 1 * 1%;
    }

    &.active {
        opacity: 1;
    }

    .func_backtotop {
        margin-top: 2px;
    }
}

.funcbtn_txt {
    font-family: $font;
    font-size: 16px;
    letter-spacing: 2px;
    text-align: center;
    white-space: nowrap;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 5px;
}

.funcbtn_control {
    padding-bottom: 2px;
    position: relative;
}

.func_control {
    width: 86px;

    &::before,
    &::after {
        background-image: url('../../img/icon_sprite_2.png');
    }

    &::before {
        background-position: 0% 100%;
    }

    &::after {
        background-position: 100% 100%;
    }

    &.active {
        &::before {
            opacity: 0;
        }

        &::after {
            opacity: 1;
        }

        &+.func_control_txt {
            opacity: 0;
        }
    }
}

.func_control_txt {
    white-space: nowrap;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    transition: opacity .2s ease-out;
    margin-top: -5px;
}

.func_instruction {
    // width: 86px;

    // &::before,
    // &::after {
    //     background-image: url('../../img/icon_sprite_2.png');
    // }

    &::before {
        background-position: 0% 0%;
    }

    &::after {
        background-position: 100% 0%;
    }
}

.func_instruction_txt {
    white-space: nowrap;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    margin-bottom: 2px;
}

.func_txt_plus {
    &::before {
        background-position: 0% 100 / 7 * 1 * 1%;
    }

    &::after {
        background-position: 100% 100 / 7 * 1 * 1%;
    }
}

.func_txt_minus {
    &::before {
        background-position: 0% 100 / 7 * 2 * 1%;
    }

    &::after {
        background-position: 100% 100 / 7 * 2 * 1%;
    }
}

.func_share {
    &::before {
        background-position: 0% 100 / 7 * 3 * 1%;
    }

    &::after {
        background-position: 100% 100 / 7 * 3 * 1%;
    }
}

.func_copy {
    &::before {
        background-position: 0% 100 / 7 * 4 * 1%;
    }

    &::after {
        background-position: 100% 100 / 7 * 4 * 1%;
    }
}

.func_pinterest {
    &::before {
        background-position: 0% 100 / 7 * 5 * 1%;
    }

    &::after {
        background-position: 100% 100 / 7 * 5 * 1%;
    }
}

.func_facebook {
    &::before {
        background-position: 0% 100 / 7 * 6 * 1%;
    }

    &::after {
        background-position: 100% 100 / 7 * 6 * 1%;
    }
}

.func_volume {
    &::before {
        background-position: 0% 100 / 7 * 7 * 1%;
    }

    &::after {
        background-position: 100% 100 / 7 * 7 * 1%;
    }
}

.func_share_trigger {

    &.active,
    &:hover {
        .social_list {
            display: flex;
        }
    }

    &.active {
        .func_share {

            &::before {
                opacity: 0;
            }

            &::after {
                opacity: 1;
            }
        }
    }
}

.func_info {
    width: 76px;
    // margin-bottom: 65px;

    &::before,
    &::after {
        background-image: url('../../img/icon_sprite_5.png');
    }

    &::before {
        background-position: 0% 100%;
    }

    &::after {
        background-position: 100% 100%;
    }

    &.active {
        &::before {
            opacity: 0;
        }

        &::after {
            opacity: 1;
        }

        // &+.func_control_txt {
        //     opacity: 0;
        // }
    }
}

.social_list {
    display: none;
    position: absolute;
    top: 0;
    right: 100%;
    margin-right: -14px;
}

.social_li {
    padding: 2px;
}



.instruction_box {
    height: 100vh;
    background: rgba($color, .9);
    padding: 55px 135px 55px 40px;
    box-sizing: border-box;
    box-shadow: -7px 0 20px rgba(#000, .25);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 55;
    display: none;

    .instruction_txt {
        font-size: 19px;
        color: $txtColor;
        line-height: (25/19);
        letter-spacing: 0;
    }
}

.instruction_line {
    width: 396px;
}

.instruction_sec {
    padding: 20px 0;

    &.inst_1 {
        padding-bottom: 5px;
    }

    &.inst_2 {
        padding-top: 38px;
        padding-bottom: 43px;
    }

    &.inst_3 {
        padding-top: 30px;
        padding-bottom: 43px;
    }
}

.instruction_title {
    font-size: 21px;
    white-space: nowrap;
    margin-bottom: 40px;
}

.instruction_row {
    max-width: 368px;
    display: flex;
    flex-wrap: wrap;
}

.instruction_col {
    padding: 0 30px;
}

.instruction_item.ins_row {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-left: 65px;
}

.instruction_icon {
    width: 49px;
    background-image: url('../../img/icon_sprite_4.png');
    background-size: 200%;
    margin: 0 auto 20px;

    &.icon_1 {
        width: 124px;
        background-image: url('../../img/icon_sprite_3.png');
        background-size: 100%;
        background-position: 0 0;
    }

    &.icon_2 {
        width: 124px;
        background-image: url('../../img/icon_sprite_3.png');
        background-size: 100%;
        background-position: 0 100%;
    }

    &.icon_3 {
        background-position: 0 0;
    }

    &.icon_4 {
        background-position: 0% 100 / 4 * 1 * 1%;
    }

    &.icon_5 {
        background-position: 0% 100 / 4 * 2 * 1%;
    }

    &.icon_6 {
        background-position: 0% 100 / 4 * 3 * 1%;
    }

    &.icon_7 {
        background-position: 0% 100 / 4 * 4 * 1%;
    }
}